
import StrapiImage from '@/components/StrapiImage'
import Animation from '@/components/Animation'
export default {
  components: {
    StrapiImage,
    Animation,
  },
  props: {
    media: {
      type: Object,
      default: () => {
        return {}
      },
    },
    title: {
      type: String,
      default: '',
    },
    bg: {
      type: Boolean,
      default: false,
    },
    heroAlignment: {
      type: String,
      default: 'center center',
    },
  },
  computed: {
    titleSize() {
      return this.title.length < 30 ? 'h1' : 'h2'
    },
  },
}
