export const infoContent = `
info {
  data {
    attributes {
      opening_hours
      announcement {
        text
        show
        background
        textcolor
        link
      }
    }
  }
}`