import { modules } from '../subqueries/modules'
import { seo } from '../subqueries/seo'

export const pageContent = `
data {
  id
  attributes {
    __typename
    slug
    title
    displaytitle
    heroAlignment
    password
    hero {
      data {
        attributes {
          width
          height
          caption
          mime
          url
          hash
          ext
        }
      }
    }
    media {
      data {
        attributes {
          width
          height
          caption
          mime
          url
          hash
          ext
        }
      }
    }
    info
    description
    ${modules}
    ${seo}
  }
}`