import { render, staticRenderFns } from "./StrapiImage.vue?vue&type=template&id=4defad57&scoped=true&"
import script from "./StrapiImage.vue?vue&type=script&lang=js&"
export * from "./StrapiImage.vue?vue&type=script&lang=js&"
import style0 from "./StrapiImage.vue?vue&type=style&index=0&id=4defad57&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4defad57",
  null
  
)

export default component.exports