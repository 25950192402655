import { teasersQuery } from '~/graphql/queries/teasers'
import { configQuery } from '~/graphql/queries/config'
import { infoQuery } from '~/graphql/queries/info'
import { pageQuery } from '~/graphql/queries/page'

export const state = () => ({
  showMenu: false,
  articles: [],
  articleCategories: [],
  events: [],
  eventCategories: [],
  recipes: [],
  stores: [],
  storeTypes: [],
  recipeMethods: [],
  recipeTypes: [],
  pages: [],
  products: [],
  whisky: {},
  productCategories: [],
  spiritCategories: [],
  config: [],
  info: [],
  scrollLocked: false,
  menu: null,
  colormode: 'light',
  submenuHover: null,
  menuHover: null,
  errorPage: null,
  showLanguages: true,
  submenusLoaded: false,
  consent: false,
  consentError: false
})

export const mutations = {
  toggleMenu(state) {
    state.showMenu = !state.showMenu
  },
  hideMenu(state) {
    state.showMenu = false
  },
  setSubmenusLoaded(state) {
state.submenusLoaded = true
  },
  showMenu(state) {
    state.showMenu = true
  },
  setShowLanguages(state, data) {
    state.showLanguages = data
  },
  setConsent(state, data) {
    state.consent = data
  },
  setConsentError(state, data) {
    state.consentError = data
  },

  setData(state, data) {
    const whiskys = data.products.data.filter(product => {
      if (product.attributes.product_category.data) {
        return parseInt(product.attributes.product_category.data.id) === 1
      } else {
        return false
      }  
    })
    const whiskyObj = {}
    const whiskyArr = []
    let count = 0
    whiskys.forEach(whisky => {
      if (!whiskyObj[whisky.attributes.displaytitle]) {
        whiskyObj[whisky.attributes.displaytitle] = {}
        whiskyObj[whisky.attributes.displaytitle].items = []
        whiskyObj[whisky.attributes.displaytitle].index = count
        count++
      }
      whiskyObj[whisky.attributes.displaytitle].items.push(whisky)
    })

    Object.keys(whiskyObj).forEach(whisky => {
      whiskyArr.push(whiskyObj[whisky].items)
    })
    state.whisky = whiskyArr

    state.recipes = data.recipes.data
    state.recipes.forEach(recipe => {
      const spirits = {data: []}
      recipe.attributes.products.data.forEach(product => {
        const p = data.products.data.find(p => p.id === product.id)
        if (p) {
          spirits.data.push(p.attributes.product_category.data)
        }
          
      })
      recipe.attributes.spirits = spirits
    })


    state.products = data.products.data
    state.articles = data.articles.data
    state.stores = data.stores.data
    state.storeTypes = data.storeTypes.data
    state.events = data.events.data
    state.recipeMethods = data.recipeMethods.data
    state.recipeTypes = data.recipeTypes.data
    state.pages = data.pages.data
    state.articleCategories = data.articleCategories.data
    state.eventCategories = data.eventCategories.data
    state.productCategories = data.productCategories.data
    state.spiritCategories = data.productCategories.data.filter(category => category.attributes.spirit !== false)
  },
  setConfig(state, data) {
    state.config = data
  },
  setInfo(state, data) {
    state.info = data
  },
  setErrorPage(state, data) {
    state.errorPage = data
  },
  setMenu(state, data) {
    state.menu = data
  },
  setColormode(state, data) {
    state.colormode = data
  },
  setSubmenuHover(state, data) {
    state.submenuHover = data
  },
  setMenuHover(state, data) {
    state.menuHover = data
  }
}

export const actions = {
  reset ({commit}) {
    commit('resetColors')
    commit('hideMenu')
  },
  toggleMenu ({commit}) {
    commit('toggleMenu')
  },
  hideMenu ({commit}) {
    commit('hideMenu')
  },
  showMenu ({commit}) {
    commit('showMenu')
  },
  lockScroll(state, value) {
    state.scrollLocked = value
  },
  async nuxtServerInit({ commit }, { app, payload, route}) {

    if(payload) {
      commit('setData', payload.data)
    } else if (process.env.NODE_ENV === 'development' || route.query.preview) {
      await app.apolloProvider.defaultClient.query({ query: teasersQuery }).then(response => {
        commit('setData', response.data)
      });
    }

    if(payload) {
      commit('setConfig', payload.data.config.data.attributes)
    } else if (process.env.NODE_ENV === 'development' || route.query.preview) {
      await app.apolloProvider.defaultClient.query({ query: configQuery }).then(response => {
        commit('setConfig', response.data.config.data.attributes)
      });
    }
    if (payload) {
      const errorPage = payload.data.pages.data.find(page => Number(page.id) === 69)
      if (errorPage) {
        commit('setErrorPage', errorPage.attributes)
      }  
    } else if (process.env.NODE_ENV === 'development' || route.query.preview) {
      await app.apolloProvider.defaultClient.query({
        query: pageQuery,
        variables: {
          slug: 'not-found',
          publicationState: 'LIVE'
        }
      }).then(page => {
        commit('setErrorPage', page.data.pages.data[0].attributes)
      });
    }
    if (payload) {
      commit('setInfo', payload.data.info.data.attributes)
    } else if (process.env.NODE_ENV === 'development' || route.query.preview) {
      await app.apolloProvider.defaultClient.query({ query: infoQuery }).then(response => {
        commit('setInfo', response.data.info.data.attributes)
      });
    }

    if (process.server || route.query.preview) {
      const menu = await this.$axios.$get(app.$strapi.options.url + `/menus/1?nested=true&populate=deep,3`, { useCache: true })
      commit('setMenu', menu)
  
    }    
  }
}